import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'

import { makeRequest } from './ApiCalls'

function Login(props) {
  const { onClose } = props

  const [loginMessage, setLoginMessage] = useState('')
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')

  useEffect(() => {
    setReadyToSubmit(userEmail.length > 0 && userPassword.length > 0)
  }, [userEmail, userPassword])

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    console.log('Submitting login')
    makeRequest(
      'POST',
      'api/login',
      null,
      {
        email: userEmail,
        password: userPassword
      }
    ).then((resp) => {
      if (resp) {
        onClose(resp)
      } else {
        setLoginMessage('Invalid credentials')
        setUserEmail('')
        setUserPassword('')
      }
    })
  }

  const handleUserEmailChange = (evt) => {
    setUserEmail(evt.target.value)
  }

  const handleUserPasswordChange = (evt) => {
    setUserPassword(evt.target.value)
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group
        className='mb-3'
        controlId='userEmail'
      >
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          name='userEmail'
          value={userEmail}
          autoFocus
          onChange={handleUserEmailChange}
        />
      </Form.Group>
      <Form.Group
        className='mb-3'
        controlId='userPassword'
      >
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          name='userPassword'
          type='password'
          value={userPassword}
          onChange={handleUserPasswordChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{loginMessage}</Form.Label>
      </Form.Group>
      <Stack direction='horizontal' gap={3}>
        <Button onClick={handleSubmit} variant='primary' type='submit' disabled={!readyToSubmit}>Login</Button>
      </Stack>
    </Form>
  )
}

export default Login