import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'

function RegistrationsModal(props) {
  const { showIt, sessionInfo, onClose } = props

  const [registrations, setRegistrations] = useState([])

  useEffect(() => {
    if (showIt) {
      makeRequest(
        'GET',
        'api/registrations',
        sessionInfo.token
      ).then(resp => setRegistrations(resp.registrations))
    }
  }, [showIt]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    onClose()
  }

  const translateStatus = (raw_status) => {
    let status = 'Error'

    switch (raw_status) {
      case 0:
        status = 'Open'
        break
      case 1:
        status = 'Completed'
        break
      case 2:
        status = 'Expired'
        break
      default:
        status = 'Error'
    }
    return status
  }

  return (
      <Modal show={showIt} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Registrations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Key</th>
                <th>Expires</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {registrations.map((row, index) => (
                <tr key={index}>
                  <td>{row.email}</td>
                  <td>{row.registration_key}</td>
                  <td>{row.expires_at}</td>
                  <td>{translateStatus(row.status)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>Done</Button>
        </Modal.Footer>
      </Modal>

  )
}

export default RegistrationsModal
