import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { makeRequest } from './ApiCalls'

function CreateRegistrationModal(props) {
  const { showIt, onClose, sessionInfo } = props

  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userName, setUserName] = useState('')

  useEffect(() => {
    setReadyToSubmit(userEmail.length > 0 && userName.length > 0)
  }, [userEmail, userName])

  useEffect(() => {
    if (!showIt) {
      setUserEmail('')
      setUserName('')
    }
  }, [showIt])

  const handleClose = (evt) => {
    console.log('Closing dialog')
    onClose()
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    console.log('Submitting registration')
    makeRequest(
      'POST',
      'api/registrations',
      sessionInfo.token,
      {
        email: userEmail,
        name: userName
      }
    ).then(resp => onClose(resp))
  }

  const handleUserEmailChange = (evt) => {
    setUserEmail(evt.target.value)
  }

  const handleUserNameChange = (evt) => {
    setUserName(evt.target.value)
  }

  return (
    <Modal show={showIt} onHide={handleClose}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Registration</Modal.Title>
        </Modal.Header>
          {sessionInfo && sessionInfo.role === 1 && (
            <Modal.Body>
              <Form.Group
                className='mb-3'
                controlId='userEmail'
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  name='userEmail'
                  value={userEmail}
                  autoFocus
                  onChange={handleUserEmailChange}
                />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='userName'
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  name='userName'
                  value={userName}
                  onChange={handleUserNameChange}
                />
              </Form.Group>
            </Modal.Body>
            )}
            {sessionInfo && sessionInfo.role !== 1 && (
              <Modal.Body>
                <p>You must be an admin to create a new user registration.</p>
              </Modal.Body>
            )}
            {!sessionInfo && (
              <Modal.Body>
                <p>You must be logged in to create a new user registration.</p>
              </Modal.Body>
            )}
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Cancel</Button>
          <Button variant='primary' type='submit' disabled={!readyToSubmit}>Create</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CreateRegistrationModal