import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import ReadingHistoryModal from './ReadingHistoryModal'
import DeleteReadingHistoryModal from './DeleteReadingHistoryModal'
import FilterModal from './FilterModal'
import { buildUrl } from './urlUtils'

function HistoryTab(props) {
  const { open, sessionInfo } = props

  const [addingRow, setAddingRow] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [filters, setFilters] = useState([])
  const [readingHistory, setReadingHistory] = useState([])
  const [readingHistoryChanged, setReadingHistoryChanged] = useState(false)
  const [showDeleteReadingHistoryModal, setShowDeleteReadingHistoryModal] = useState(false)
  const [showReadingHistoryModal, setShowReadingHistoryModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/reading_history',
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      ).then(history => setReadingHistory(history))
    }
  }, [open, readingHistoryChanged, sortOrder, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleEditButtonClick = () => {
    setAddingRow(false)
    setShowReadingHistoryModal(true)
  }

  const handleAddButtonClick = () => {
    setAddingRow(true)
    setShowReadingHistoryModal(true)
  }

  const handleDeleteButtonClick = () => {
    setShowDeleteReadingHistoryModal(true)
  }

  const handleCloseReadingHistoryModal = (edited) => {
    console.log(`Current Row: ${JSON.stringify(currentRow, null, 2)}`)
    setShowReadingHistoryModal(false)
    if (edited) {
      setReadingHistoryChanged(!readingHistoryChanged)
    }
  }

  const handleCloseDeleteReadingHistoryModal = (edited) => {
    setShowDeleteReadingHistoryModal(false)
    if (edited) {
      setReadingHistoryChanged(!readingHistoryChanged)
    }
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
            <Button className='ms-auto' onClick={handleEditButtonClick} disabled={!currentRow}>
              Edit
            </Button>
            <Button onClick={handleAddButtonClick}>
              Add
            </Button>
            <Button onClick={handleDeleteButtonClick} disabled={!currentRow} variant='danger'>
              Delete
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <Table striped bordered hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th onClick={evt => handleColumnClick('title')}>Title
                    {sortOrder && sortOrder.sortColumn === 'title' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'title' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('authors')}>Authors
                    {sortOrder && sortOrder.sortColumn === 'authors' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'authors' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('date_started')}>Date Started
                    {sortOrder && sortOrder.sortColumn === 'date_started' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'date_started' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('date_ended')}>Date Ended
                    {sortOrder && sortOrder.sortColumn === 'date_ended' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'date_ended' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {readingHistory.map((row, index) => (
                  <tr
                    key={index} 
                    onClick={() => handleRowClick(row)}
                    className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                  >
                    <td>{row.title}</td>
                    <td>{row.authors}</td>
                    <td>{row.date_started}</td>
                    <td>{row.date_ended}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReadingHistoryModal
            showIt={showReadingHistoryModal}
            historyId={currentRow ? addingRow ? null : currentRow.id : null}
            originalBookId={currentRow ? addingRow ? '' : currentRow.book_id : ''}
            originalDateStarted={currentRow ? addingRow ? new Date() : new Date(currentRow.date_started + 'T00:00') : new Date()}
            originalDateEnded={currentRow ? addingRow ? new Date() : new Date(currentRow.date_ended + 'T00:00') : new Date()}
            sessionInfo={sessionInfo}
            onClose={handleCloseReadingHistoryModal} />
          <DeleteReadingHistoryModal
            showIt={showDeleteReadingHistoryModal}
            readingHistory={currentRow}
            sessionInfo={sessionInfo}
            onClose={handleCloseDeleteReadingHistoryModal} />
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            filterColumns={[ 
              { columnName: 'title', title: 'Title' }, 
              { columnName: 'authors', title: 'Authors' }, 
              { columnName: 'year', title: 'Year' }, 
              { columnName: 'month', title: 'Month' }
            ]} />
        </Col>
      </Row>
    </Container>
  )
}

export default HistoryTab