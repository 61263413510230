import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { makeRequest } from './ApiCalls'

function CompleteRegistrationModal(props) {
  const { showIt, onClose } = props

  const [confirmPassword, setConfirmPassword] = useState('')
  const [password, setPassword] = useState('')
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [registrationKey, setRegistrationKey] = useState('')
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    setReadyToSubmit(userEmail.length > 0 && registrationKey.length > 0 && password.length > 0 && password === confirmPassword)
  }, [userEmail, registrationKey, password, confirmPassword])

  useEffect(() => {
    if (!showIt) {
      setUserEmail('')
      setRegistrationKey('')
      setPassword('')
      setConfirmPassword('')
    }
  }, [showIt])

  const handleClose = (evt) => {
    onClose()
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    makeRequest(
      'PUT',
      `api/registrations/${registrationKey}`,
      null,
      { email: userEmail }
    ).then((resp) => {
      if (resp.valid) {
        makeRequest(
          'PUT',
          'api/registrations/secure',
          resp.completion_info.token,
          {
            email: userEmail,
            password: password
          }
        ).then(sResp => onClose(sResp))
      } else {
        onClose(resp)
      }
    })
  }

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value)
  }

  const handleConfirmPasswordChange = (evt) => {
    setConfirmPassword(evt.target.value)
  }

  const handleUserEmailChange = (evt) => {
    setUserEmail(evt.target.value)
  }

  const handleRegistrationKeyChange = (evt) => {
    setRegistrationKey(evt.target.value)
  }

  return (
    <Modal show={showIt} onHide={handleClose}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className='mb-3'
            controlId='userEmail'
          >
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              name='userEmail'
              value={userEmail}
              autoFocus
              onChange={handleUserEmailChange}
            />
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='registrationKey'
          >
            <Form.Label>Registration Key</Form.Label>
            <Form.Control
              required
              name='registrationKey'
              value={registrationKey}
              onChange={handleRegistrationKeyChange}
            />
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='password'
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              name='password'
              type='password'
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='confirmPassword'
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              name='confirmPassword'
              type='password'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Cancel</Button>
          <Button variant='primary' type='submit' disabled={!readyToSubmit}>Complete</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CompleteRegistrationModal