import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { makeRequest } from './ApiCalls'

function DeleteReadingHistoryModal (props) {
  const { showIt, readingHistory, sessionInfo, onClose } = props

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

      makeRequest(
        'DELETE',
        `api/books/${readingHistory.book_id}/readings/${readingHistory.id}`,
        sessionInfo.token
      ).then((resp) => {
        console.log(JSON.stringify(resp))
        onClose(true)
      })
  }

  const handleClose = (evt) => {
    onClose(false)
  }

  return (
    <Modal show={showIt} onHide={handleClose}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Delete Reading History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Are you sure you want to delete this reading history entry?</Form.Label>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
          >
            Delete
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default DeleteReadingHistoryModal