import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest, searchBooks } from './ApiCalls'

function AdddBookModal (props) {
  const { showIt, sessionInfo, onClose } = props

  const [ author, setAuthor] = useState('')
  const [ currentRow, setCurrentRow ] = useState(null)
  const [ searchCriteriaChanged, setSearchCriteriaChanged] = useState(false)
  const [ books, setBooks ] = useState({ docs: [] })
  const [ title, setTitle ] = useState('')
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showIt) {
      if (author.length > 0 || title.length > 0) {
        searchBooks(title, author)
        .then(books => setBooks(books))
      }
    }
  }, [showIt, searchCriteriaChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTitleChanged = (evt) => {
    setTitle(evt.target.value)
  }

  const handleAuthorChanged = (evt) => {
    setAuthor(evt.target.value)
  }

  const handleSearchClicked = () => {
    setSearchCriteriaChanged(!searchCriteriaChanged)
  }

  const handleClose = () => {
    setTitle('')
    setAuthor('')
    setBooks({ docs: [] })
    setCurrentRow(null)
    onClose(false)
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    if (currentRow) {
      const authors = []
      let i = 0
      for (let name of currentRow.author_name) {
        authors.push({
          name: name,
          ol_key: currentRow.author_key[i]
        })
        i += 1
      }
      makeRequest(
        'POST',
        'api/books',
        sessionInfo.token,
        {
          title: currentRow.title,
          year_published: currentRow.publish_year.length > 0 ? currentRow.publish_year[0] : 0,
          ol_key: currentRow.key,
          authors: authors
        }
      ).then((resp) => {
        console.log(JSON.stringify(resp, null, 2))
        onClose(true)
      })
    } else {
      onClose(false)
    }

    setTitle('')
    setAuthor('')
    setCurrentRow(null)
    setBooks({ docs: [] })
  }

  const handleRowClick = (row) => {
    setCurrentRow(row)
  }

  return (
    <Modal show={showIt} onHide={handleClose} size='xl'>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Add Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Stack direction='horizontal' gap={3}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control onChange={handleTitleChanged} />
                  <Form.Label>Author</Form.Label>
                  <Form.Control onChange={handleAuthorChanged} />
                  <Button variant='primary' onClick={handleSearchClicked}>Search</Button>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ maxHeight: `${windowSize.innerHeight - 400}px`, overflow: 'auto'}}>
                  <Table striped bordered hover>
                    <thead style={{ position: 'sticky', top: '0' }}>
                      <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Published</th>
                        <th>Key</th>
                      </tr>
                    </thead>
                    <tbody>
                      {books.docs.map((row, index) => (
                        <tr 
                          key={index}
                          onClick={() => handleRowClick(row)}
                          className={currentRow && currentRow.key === row.key ? 'table-primary' : ''}
                        >
                          <td>{row.title}</td>
                          <td>{row.author_name ? row.author_name.join(',') : ''}</td>
                          <td>{row.publish_year ? row.publish_year.length > 0 ? row.publish_year[0] : '' : '' }</td>
                          <td>{row.key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={!currentRow}
            variant='primary'
          >
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  )
}

export default AdddBookModal