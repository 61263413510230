import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import AdddBookModal from './AddBookModal'
import FilterModal from './FilterModal'
import BookDetailModal from './BookDetailModal'
import { buildUrl } from './urlUtils'

function BooksTab(props) {
  const { open, sessionInfo } = props

  const [bookAdded, setBookAdded] = useState(false)
  const [books, setBooks] = useState([])
  const [currentRow, setCurrentRow] = useState(null)
  const [filters, setFilters] = useState([])
  const [showBookDetailModal, setShowBookDetailModal] = useState(false)
  const [showAddBookModal, setShowAddBookModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/books',
        filters,
        sortOrder
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then(books => setBooks(books))
    }
  }, [open, bookAdded, sortOrder, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleAddBookModalClose = (adding) => {
    setShowAddBookModal(false)
    if (adding) {
      setBookAdded(!bookAdded)
    }
  }

  const handleAddButtonClick = (evt) => {
    setShowAddBookModal(true)
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  const handleDetailButtonClick = () => {
    if (currentRow) {
      setShowBookDetailModal(true)
    }
  }

  const handleCloseBookDetailModal = () => {
    setShowBookDetailModal(false)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
            <Button disabled={!currentRow} className='ms-auth' onClick={handleDetailButtonClick}>Detail</Button>
            <Button className='ms-auto' onClick={handleAddButtonClick}>
              Add
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <Table striped bordered hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th onClick={evt => handleColumnClick('title')}>Title
                    {sortOrder && sortOrder.sortColumn === 'title' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'title' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('authors')}>Authors
                    {sortOrder && sortOrder.sortColumn === 'authors' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'authors' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('year_published')}>First Published
                    {sortOrder && sortOrder.sortColumn === 'year_published' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'year_published' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                  <th onClick={evt => handleColumnClick('num_reads')}>Num Reads
                    {sortOrder && sortOrder.sortColumn === 'num_reads' && sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>   
                    )}
                    {sortOrder && sortOrder.sortColumn === 'num_reads' && !sortOrder.ascending && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                      </svg>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {books.map((row, index) => (
                  <tr 
                    key={index}
                    onClick={() => handleRowClick(row)}
                    className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                  >
                    <td>{row.title}</td>
                    <td>{row.authors}</td>
                    <td>{row.year_published}</td>
                    <td>{row.num_reads}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdddBookModal
            showIt={showAddBookModal}
            sessionInfo={sessionInfo}
            onClose={handleAddBookModalClose} />
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            filterColumns={[ 
              { columnName: 'title', title: 'Title' }, 
              { columnName: 'authors', title: 'Authors' }, 
              { columnName: 'year_published', title: 'Year Published' }, 
            ]} />
          <BookDetailModal
            showIt={showBookDetailModal}
            bookKey={currentRow ? currentRow.ol_key : null}
            onClose={handleCloseBookDetailModal} />
        </Col>
      </Row>
    </Container>
  )
}

export default BooksTab
