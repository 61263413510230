import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'

function UsersModal(props) {
  const { showIt, sessionInfo, onClose } = props

  const [users, setUsers] = useState([])

  useEffect(() => {
    if (showIt) {
      makeRequest(
        'GET',
        'api/users',
        sessionInfo.token
      ).then(resp => setUsers(resp.users))
    }
  }, [showIt]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    onClose()
  }

  const translateRole = (raw_role) => {
    return raw_role === 0 ? 'User' : 'Admin'
  }

  const translateRegistrationComplete = (raw_registration_complete) => {
    return raw_registration_complete === 1 ? 'X' : ''
  }

  return (
      <Modal show={showIt} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Registration Complete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((row, index) => (
                <tr key={index}>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{translateRole(row.role)}</td>
                  <td>{translateRegistrationComplete(row.registration_complete)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>Done</Button>
        </Modal.Footer>
      </Modal>

  )
}

export default UsersModal
