import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import DatePicker from 'react-datepicker'
import { makeRequest } from './ApiCalls'

function ReadingHistoryModal (props) {
  const { showIt, historyId, originalBookId, originalDateStarted, originalDateEnded, sessionInfo, onClose } = props

  const [bookId, setBookId] = useState(null)
  const [books, setBooks] = useState([])
  const [dateStarted, setDateStarted] = useState(null)
  const [dateEnded, setDateEnded] = useState(null)

  useEffect(() => {
    if (showIt) {
      makeRequest(
        'GET',
        'api/books?sort_column=title&sort_dir=asc',
        sessionInfo.token
      )
      .then(bks => setBooks(bks))
    }

  }, [showIt]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(`BookID: ${originalBookId}`)
    if (showIt) {
      setBookId(originalBookId)
      setDateStarted(originalDateStarted)
      setDateEnded(originalDateEnded)
    }
  }, [showIt, originalBookId, originalDateStarted, originalDateEnded])

  const handleBookIdChange = (evt) => {
    setBookId(evt.target.value)
  }

  const handleDateStartedChanged = (date) => {
    setDateStarted(date)
  }

  const handleDateEndedChanged = (date) => {
    setDateEnded(date)
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    const ds = dateStarted.toISOString().split('T')[0]
    const de = dateEnded ? dateEnded.toISOString().split('T')[0] : null

    const body = {
      user_id: sessionInfo.user_id,
      date_started: ds,
      date_ended: de
    }

    if (historyId) {
      // We're editing a history entry
      makeRequest(
        'PUT',
        `api/books/${bookId}/readings/${historyId}`,
        sessionInfo.token,
        body
      ).then((resp) => {
        console.log(JSON.stringify(resp))
        onClose(true)
      })
    } else {
      // We're creating a new history entry
      makeRequest(
        'POST',
        `api/books/${bookId}/readings`,
        sessionInfo.token,
        body
      ).then((resp) => {
        console.log(JSON.stringify(resp))
        onClose(true)
      })
    }
  }

  const handleClose = (evt) => {
    onClose(false)
  }

  return (
    <Modal show={showIt} onHide={handleClose}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{historyId ? 'Edit History' : 'Add History'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Book</Form.Label>
            <Form.Select
              required
              id='book-select'
              name='book'
              value={bookId ? bookId : ''}
              onChange={handleBookIdChange}
            >
              <option value=''>Select Book</option>
              {books.map(book => (
                <option value={book.id} key={book.id}>{book.title}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Date Started</Form.Label>
            <DatePicker
              required
              selected={dateStarted}
              onChange={handleDateStartedChanged} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date Ended</Form.Label>
            <DatePicker
              required
              selected={dateEnded}
              onChange={handleDateEndedChanged} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
          >
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ReadingHistoryModal